@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .select {
    @apply bg-select-arrow-down bg-no-repeat bg-[90%_50%] bg-[length:18px_18px];
  }
}

input[type="checkbox"]:checked ~ .dot {
  transform: translateX(100%);
}

input[type="checkbox"]:checked ~ .dot-container {
  background-color: #DA384D;
}

/* * {
  min-height: 0;
  min-width: 0;
} */

@layer base{
  body {
      @apply dark:bg-neutral-900 bg-gray-300
  }
}

.custom-checkbox {
  width: 15px !important;
  height: 15px !important;
  border: 2px solid var(--heading-tooltip-onboarding-color);
  border-radius: 3px;
  appearance: none;
  display: inline-block;
  position: relative;
}

.custom-checkbox:checked {
  border-color: #DA384D;
  background-color: #DA384D;
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 7px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
